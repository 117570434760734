<template>
    <div class="home">
        <div v-if="partList[1].state" class="center">
            <div class="introduction">
                <div class="title">
                    <div class="title_zh">会议简介</div>
                    <div class="title_en">Conference Introduction</div>
                </div>
                <div class="introduction_text ">
                    <div class="theme_img fl">
                        <img :src=getImgView(theme_img) alt="">
                    </div>
                    <div>
                        {{ Alldata.conferenceIntroduction }}
                    </div>

                    <div class="clear"></div>
                </div>

            </div>
        </div>
        <div v-if="partList[2].state" class="address">
            <div class="center">
                <div class="content">
                    <div class="title_white">
                        <div class="title_zh">时间与地点</div>
                        <div class="title_en">Time and place</div>
                    </div>
                    <div class="box">
                        <div class="time_box">
                            <div class="time_icon">
                                <img class="icon" src="../assets/time2.png" alt="">
                            </div>
                            <p class="time">会议时间：{{ Alldata.meetingTime }}</p>
                        </div>
                        <div class="address_box">
                            <div class="time_icon">
                                <img class="icon" src="../assets/address2.png" alt="">
                            </div>
                            <p class="time">会议地点：<span>{{ Alldata.meetingPlace }}</span></p>
                            <!-- <img src="../assets/address.png" alt=""> -->
                        </div>
                    </div>
                    <div class="countdown_box">
                        <span class="countdown_text">距离会议开始还有：</span>
                        <el-statistic ref="statistic" @finish="hilarity" format="DD天" :value="deadline4"
                            time-indices></el-statistic>
                    </div>
                    <!-- <img class="time_bg" src="../assets/time.png" alt=""> -->
                </div>
            </div>
        </div>
        <div v-if="partList[3].state" class="center">
            <div class="Committee">
                <div class="title">
                    <div class="title_zh">会议学术委员会</div>
                    <div class="title_en">Conference Academic Committee</div>
                </div>
                <div class="CommitteeList">
                    <div class="Academic" v-if="AcademicList.position[0].name">
                        <div class="position">主席</div>
                        <div class="name_box">
                            <div class="name">{{ AcademicList.position[0].name }}</div>
                            <div class="position_title">{{ AcademicList.position[0].academicDegree }}</div>
                            <div class="unit">{{ AcademicList.position[0].school }}</div>
                        </div>
                    </div>
                    <div class="Academic member">
                        <div class="position">成员</div>
                        <div class="member_list">
                            <div class="name_box" v-for="(item, index) in AcademicList.member" :key="index">
                                <div class="name">{{ item.name }}</div>
                                <div class="position_title">{{ item.academicDegree }}</div>
                                <div class="unit">{{ item.school }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="partList[4].state" class="center">
            <div class="Committee">
                <div class="title">
                    <div class="title_zh">会议组织委员会</div>
                    <div class="title_en">Conference Organizing Committee</div>
                </div>
                <div class="Organizing_box">
                    <div class="Organizing">
                        <div class="Organizing_title">会议组织委员会主席</div>
                        <el-card shadow="hover" style="margin-bottom: 10px;">
                            <div class="name">{{ OrganizingList.position[0].name }}<span>{{ OrganizingList.position[0].school
                            }}</span></div>
                            <div class="line"></div>
                            <div class="position_title">{{ OrganizingList.position[0].academicDegree }}</div>
                            <!-- <div class="unit">会议组织委员会主席</div> -->
                        </el-card>
                        <div class="Organizing_title">委员会秘书长</div>
                        <el-card shadow="hover">
                            <div class="name">{{ OrganizingList.secretary[0].name }}<span>{{
                                OrganizingList.secretary[0].school }}</span></div>
                            <div class="line"></div>
                            <div class="position_title">{{ OrganizingList.secretary[0].academicDegree }}</div>
                            <!-- <div class="unit">委员会秘书长</div> -->
                        </el-card>
                    </div>
                    <div class="Organizing OrganizingList">
                        <div class="Organizing_title">委员会执行主任</div>
                        <div style="width: 100%;display: flex;justify-content: space-between; flex-wrap: wrap;">
                            <el-card style="width: 250px; margin-bottom: 20px;" shadow="hover"
                                v-for="(item, index) in OrganizingList.member" :key="index">
                                <div class="name">{{ item.name }}<span>{{ item.school }}</span></div>
                                <div class="line"></div>
                                <div class="position_title">{{ item.academicDegree }}</div>
                            </el-card>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return {
            theme_img: '',
        }
    },
    mounted() {
        this.getDate();
    },
    methods: {
        hilarity() {
            this.$notify({
                title: "提示",
                message: "会议已经开始，请及时参加",
                duration: 0,
            });
        },
    }
}
</script>

<style lang="less" scoped>
.home {
    .introduction {
        padding: 35px 40px;

        .introduction_text {
            margin-bottom: 30px;

            .theme_img {
                width: 300px;
                max-height: 200px;
                overflow: hidden;
                margin-right: 30px;
                border: 2px solid #4fb3fa;
                box-sizing: border-box;
                // border-radius: 5px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            p {
                text-indent: 2em;
            }
        }
    }

    .address {
        width: 100%;
        height: auto;
        background-color: #2B77AE;

        .content {
            padding: 35px 40px 50px;
            position: relative;

            .box {
                display: flex;
                justify-content: space-between;
                align-items: top;
                flex-wrap: wrap;
                color: #ffffff;
                border: 1px solid #ffffff;
                padding: 5px;

                .address_box {
                    width: 48%;
                    overflow: hidden;
                    display: flex;
                    align-items: top;

                    .time_icon {
                        width: 25px;
                        height: 25px;
                        overflow: hidden;
                        margin-right: 5px;

                        .icon {
                            width: 100%;
                        }
                    }

                    p {
                        font-size: 18px;
                    }
                }

                .time_box {
                    width: 48%;
                    display: flex;
                    align-items: top;


                    .time_icon {
                        width: 25px;
                        height: 25px;
                        overflow: hidden;
                        margin-right: 5px;

                        .icon {
                            width: 100%;
                        }
                    }

                    p {
                        font-size: 18px;
                    }
                }



            }

            .countdown_box {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                border: 1px solid #ffffff;
                background-color: #ffffff;
                margin-top: 15px;
                box-sizing: border-box;

                .countdown_text {
                    display: inline-block;
                    width: 190px;
                    font-size: 18px;
                    color: #2B77AE;

                }
            }

            .time_bg {
                position: absolute;
                right: 30px;
                top: 0;
                width: 170px;
                opacity: 0.8;
            }
        }
    }

    .Committee {
        padding: 35px 40px;

        .CommitteeList {
            font-family: 'Verdana,Microsoft YaHei';
            // display: flex;
            // align-items: top;

            .Academic {
                // width: 20%;
                display: flex;
                align-items: center;
                background-color: #4fb3fa;
                color: #ffffff;
                cursor: pointer;

                .position {
                    font-size: 18px;
                    font-weight: bold;
                    padding: 15px 20px;
                    border-right: 1px solid #ffffff;
                    flex-shrink: 0;
                }

                .name_box {
                    width: calc(100% - 30px);
                    text-align: center;
                    padding: 15px 10px 15px 20px;
                    transition: box-shadow 0.3s ease;
                    // cursor: pointer;
                    display: flex;
                    align-items: center;
                    text-align: left;

                    .name {
                        width: 10%;
                    }

                    .position_title {
                        width: 30%;
                    }

                    .unit {
                        width: 50%;
                    }
                }

                .name_box:hover {
                    cursor: pointer;
                    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
                }

                // .name_box:hover {
                //     box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
                //     border: 1px solid #e7e7e7;
                //     box-sizing: border-box;
                // }
            }


            .member {
                background-color: rgb(255, 255, 255);
                color: #666666;

                .member_list {
                    width: calc(100% - 30px);

                    .name_box {
                        border: 1px solid #f7f7f7;
                        width: calc(100% - 30px);
                        text-align: center;
                        padding: 15px 10px 15px 20px;
                        display: flex;
                        align-items: center;
                        text-align: left;
                        transition: box-shadow 0.3s ease;

                        .name {
                            width: 10%;
                        }

                        .position_title {
                            width: 30%;
                        }

                        .unit {
                            width: 50%;
                        }
                    }

                    .name_box:hover {
                        cursor: pointer;
                        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                // border-bottom: 1px solid #e7e7e7;

            }

        }

        .Organizing_box {
            width: calc(100% - 30px);
            font-family: 'Verdana,Microsoft YaHei';
            display: flex;
            align-items: top;
            background-color: #f7f7f7;
            padding: 15px;

            .Organizing {
                width: 280px;
                flex-shrink: 0;
                padding-right: 20px;
                border-right: 1px solid #2B77AE;
                margin-right: 20px;

                .Organizing_title {
                    line-height: 30px;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 10px;
                    color: #2B77AE;
                }

                .name {
                    font-size: 18px;
                    font-weight: bold;

                    span {
                        font-size: 14px;
                        margin-left: 5px;
                    }
                }

                .line {
                    width: 100%;
                    height: 2px;
                    margin: 5px 0;
                    background-color: #e9e9e9;
                }

                .position_title {
                    font-size: 14px;
                    text-align: center;
                }

                .unit {
                    font-size: 16px;
                    color: #666666;
                    text-align: center;
                    font-weight: bold;
                }

            }

            .OrganizingList {
                width: calc(100% - 320px);
                // width: 280px;
                flex-shrink: 0;
                padding-right: 0;
                border-right: none;
                margin-right: 0px;
            }
        }
    }
}
</style>
<style>
.el-statistic .con {
    justify-content: flex-start;
    color: #2B77AE;
}
</style>